.main-contact {
  background: #121212;
}

.contact-center {
  padding: 20px;
}

.contact-center-links {
  margin-bottom: 20px;
}

.contact-center-links h3 {
  text-align: center;
  letter-spacing: 0.2rem;
  color: lightgray;
 margin-bottom: 10px;
}

.contact-center-media h3{
  text-align: center;
  letter-spacing: 0.2rem;
  color: lightgray;
 margin-bottom: 15px;
}

.contact-links {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.contact-media {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.icon-link li {
  margin-bottom: 8px;
}

.contact-links li {
  margin: 2px;
  color: rgb(192, 190, 190);
}

.contact-links li a {
  margin: 0 5px;
  color: rgb(192, 190, 190);
  font-size: 18px;
  padding: 0 2px;
}

.contact-links li a:hover {
  margin: 0 5px;
  color: rbg(226, 225, 225);
}

.contact-media li a {
  margin: 0 5px;
  color: rgb(192, 190, 190);
  padding: 5px;
  font-size: 18px;
}

.contact-media li a:hover {
  color: rgb(226, 225, 225);
}

.contact-media li a i {
  margin: 0 5px;
  color: rgb(192, 190, 190);
  font-size: 20px;
}

.footer {
  border-top: 1px solid lightgray;
  text-align: center;
  color: lightgray;
  font-style: italic;
  padding: 10px 0;
  font-size: 18px;
}

@media screen and (min-width: 530px) {
  .contact-links li {
    margin-bottom: 0;
  }
  .contact-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .icon-link {
    display: flex;
  }
}

@media screen and (min-width: 864px) {
  .contact-media li a i {
    font-size: 24px;
  }
}

@media screen and (min-width: 864px) {
  
  .contact-center {
    width: 100%;
  }
  .contact-media{
    display: flex;
    justify-content: center;
  }
}
