
.project-container{

  margin: 20px;
}

.projects {
  max-width: 1200px;
  margin: 0 auto;
}

.project-info {
  margin-bottom: 30px;
  background-color: #3B3B3B;
  border-radius: 5px;
}

.project-img {
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
}

.project-img img {
  width: 100%;
  display: block;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}



.project-info {
  padding: 14px;
}

.project-info:hover .project-img img{
    transform: scale(1.05) ;
  }

.project-info h3 {
  letter-spacing: 0.1rem;
  margin-bottom: 5px;
  color: #FFB606;
}

.project-info p {
  line-height: 1.3;
  color: gainsboro;
  font-size: 16px;
  margin-bottom: 10px;
text-align: justify;
}

.project-icons a i{
  font-size: larger;
  padding-right: 10px;
  color: #FFE002;

}

.project-icons a:hover{
  color: blueviolet;
}

@media screen and (min-width: 664px) {
  .projects-detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .project-info {
    display: flex;

  }
  .project-img {
    width: 300px;
    height: 280px;
  }
  .project-img img {
    width: 300px;
    height: 280px;
    transition: all 0.3s ease-in-out;
  }
  .project-info:hover .project-img img{
    transform: scale(1.05) ;
  }
  
}


@media screen and (min-width: 964px) {
  .projects-detail {
    max-width: 1180px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .project-info {
    display: flex;
    flex: 0 0 calc(49%);
    margin: 0 0;
    margin-bottom: 25px;
    position: relative;
  }
  .project-img {
    width: 250px;
    height: 250px;
  }
  .project-img img {
    width: 250px;
    height: 250px;
    transition: all 0.3s ease-in-out;
  }
  .project-info:hover .project-img img{
    transform: scale(1.05) ;
  }
 
}
