@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background-color: #1C1C1C;
  font-family: Helvetica;
}

.main-container,
.contact,
.main-title {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 10px;

}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.title {
  font-size: 20px;
  letter-spacing: 0.1rem;
  font-style: italic;
  border-bottom: 1px dashed #B0C4DE;
  color: #B0C4DE;
  margin-bottom: 10px;
  max-width: 1200px;
 display: flex;
 justify-content: flex-start;
}

.main-container{
  max-width: 1200px;

}