/* scroll.css */
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");


.scroll-container {
    width: 100%;
    overflow: hidden;

  }
  
  .scroll-container h4{
    color: #fff;
    text-align: center;
    margin: 10px 0;
   font-family: Arial, Helvetica, sans-serif;
   letter-spacing: 1px;

  }
  
  .techstack-container {
    display: flex;
    animation: scroll 12s linear infinite;
  }
  
  .skill-logo {
    background-color:  #FFFAF0;
    border-radius: 12px;
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    flex-shrink: 0;

  }
  
  .skill-logo img {
    max-width: 80%;
    max-height: 80%;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100px * 10));
    }
  }
  
  @media (min-width: 768px){
    .skill-logo{
      width: 180px;
      height: 80px;
    margin-right: 25px;

  
    }
    .techstack-container {
      animation: scroll 15s linear infinite;
    }
  
    .scroll-container h4{
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-300px * 10));
      }
    }
  }
  