.education{
    margin: 20px;
    max-width: 1200px;
}

.education-info{
    background: rgb(17,115,145);
    margin: 0 auto;
    margin-bottom: 20px;
    width: 100%;
    font-size: 18px;
    color: white;
    letter-spacing: 0.1rem;
    padding: 4px;
}

@media screen and (min-width: 860px) {
    .education{
        margin: 0 auto;
    
    }
    .education-detail{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .education-info{
        flex: 0 0 calc(50% - 20px);
        margin-right: 0;
        margin-left: 0;
        padding: 10px;
        clip-path: polygon(0 0,100% 0,95% 100%,0% 100%);
    }
}