.nav-container {
  background-color: #1c1c1c;
  margin: 0 auto;
  max-width: 1400px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.nav {
  max-width: 1200px;
  margin: 0 auto;
}

.logoBtn {
  display: flex;
  padding: 5px 20px;
  border-bottom: 1.5px solid gainsboro;
  align-items: center;
  justify-content: space-between;
}

.logoBtn a {
  font-size: 30px;
  color: gainsboro;
  width: 35px;
  align-self: center;
  border-radius: 50%;
  opacity: 1;
  box-shadow: 0 0 1px 2px lightgray;
}

.logoBtn a:hover {
  opacity: 0.8;
  box-shadow: 0 0 1px 3px lightgray;
}

.logoBtn img {
  width: 35px;
  display: block;
  border-radius: 50%;
  height: 40px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar1,
.bar2,
.bar3 {
  background-color: lightgray;
  width: 100%;
  padding: 1px;
  margin: 5px;
  transition: all 0.5s ease-in-out;
}

.animatedBar.bar1 {
  transform: rotate(-45deg) translate(-5px, 5px);
  background: white;
  width: 30px;
}

.animatedBar.bar2 {
  opacity: 0;
}

.animatedBar.bar3 {
  transform: rotate(45deg) translate(-4px, -5px);
  background: white;
  width: 30px;
}

.btn {
  cursor: pointer;
}

.links {
  background-color: #1c1c1c;
  width: 100%;
  transform: translate(-100%);
  position: fixed;
  top: 37px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  opacity: 1;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

.new-links {
  width: 100%;
  transform: translate(0%);
  position: fixed;
  top: 37x;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 25px;
}

.links li a {
  display: block;
  padding: 10px;
  color: lightgray;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
}

.links li a:hover {
  padding: 10px 12px;
  color: white;
}

.adminLi {
  display: none;
}

.admin {
  color: rgb(93, 241, 93);
}

@media screen and (min-width: 780px) {
  .logoBtn {
    border: none;
    padding: 0;
  }
  .btn {
    display: none;
  }
  nav {
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }

  .links {
    position: relative;
    display: flex;
    width: auto;
    top: auto;
    transform: translate(0%);
  }

  .new-links {
    width: 100%;
    transform: translate(0%);
    position: relative;
    top: auto;
  }

  .links li a:hover {
    font-size: 18px;
  }
}
