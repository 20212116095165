.about-container{
    margin: 20px;
}

.about{
    margin: 0 auto;
    max-width: 1200px;
}



.about-info p{
    text-align: justify;
    font-size: 14px;
    color: gainsboro;
    line-height: 1.5;
    padding-bottom: 6px;
}

